import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./App.css";

const App = () => {
  const [sessionId, setSessionId] = useState(null);
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([
    { from: "bot", text: "" },
  ]);
  const [loading, setLoading] = useState(false);
  const [hasUserInteracted, setHasUserInteracted] = useState(false); // Track if the user has interacted
  const sessionCreated = useRef(false);
  const chatBoxRef = useRef(null);
  const [promptId, setPromptId] = useState("alethia"); // or "proteus" for the Proteus frontend

  useEffect(() => {
    const createSession = async () => {
      if (!sessionCreated.current) {
        sessionCreated.current = true;
        try {
          console.log("Attempting to create session...");
          console.log("API URL:", `${process.env.REACT_APP_API_BASE_URL}/create-new-chat-session`);
          const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/create-new-chat-session?promptId=${promptId}`
          );
          console.log("Session created successfully:", response.data);
          setSessionId(response.data.session_id);
        } catch (error) {
          console.error("Error creating session:", error);
          console.error("Error details:", error.response ? error.response.data : "No response data");
        }
      }
    };

    createSession();

    const reportSession = () => {
      if (hasUserInteracted && sessionId) {
        const url = `${process.env.REACT_APP_API_BASE_URL}/send-chat-history/${sessionId}`;
        const data = JSON.stringify({ sessionId });
        navigator.sendBeacon(url, data); // Use sendBeacon for reliable reporting
      }
    };

    // Bind the report function to the beforeunload event
    window.addEventListener("beforeunload", reportSession);

    // Cleanup to remove the event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", reportSession);
    };
  }, [sessionId, hasUserInteracted, promptId]); // Add hasUserInteracted and promptId as dependencies

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    const userMessage = input;
    setMessages([...messages, { from: "user", text: userMessage }]);
    setInput("");
    setLoading(true);
    setHasUserInteracted(true); // Set user interaction to true

    try {
      console.log("Fetching answer...");
      console.log("API URL:", `${process.env.REACT_APP_API_BASE_URL}/fetch-answer/${sessionId}/${encodeURIComponent(userMessage)}`);
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/fetch-answer/${sessionId}/${encodeURIComponent(
          userMessage
        )}`
      );
      console.log("Answer received:", response.data);
      setMessages((prevMessages) => [
        ...prevMessages,
        { from: "bot", text: response.data.answer },
      ]);
    } catch (error) {
      console.error("Error fetching answer:", error);
      console.error("Error details:", error.response ? error.response.data : "No response data");
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          from: "bot",
          text: "Sorry, something went wrong. Please try again later.",
        },
      ]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="chat-container">
      <div className="chat-header">
        <h1 className="header-text">
          Welcome to Alethia
          <img
            className="header-logo"
            src="Aldous_Logo__1_-removebg-preview.png"
            alt="logo"
          />
        </h1>
      </div>
      <div className="chat-box" ref={chatBoxRef}>
        {messages.length === 0 ? (
          <div className="empty-chat">
            No messages yet. Start the conversation!
          </div>
        ) : (
          messages.map((msg, index) => (
            <div key={index} className={`message ${msg.from}`}>
              {msg.text}
            </div>
          ))
        )}
        {loading && <div className="loading-spinner"></div>}
      </div>
      <form onSubmit={handleSubmit} className="input-box">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Ask something..."
          required
        />
        <button type="submit">Send</button>
      </form>
    </div>
  );
};

export default App;
